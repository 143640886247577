.node {
  width: 100%;
  height: 100%;
  border-radius: 0px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}

.node :global .react-flow__resize-control.handle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}

.rotateHandle {
  position: absolute;
  width: 10px;
  height: 10px;
  background: #3367d9;
  left: 50%;
  top: -30px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  cursor: alias;
}

.rotateHandle:after {
  content: '';
  display: block;
  position: absolute;
  width: 1px;
  height: 30px;
  background: #3367d9;
  left: 4px;
  top: 5px;
}
